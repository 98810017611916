import TarotCard from './TarotCard';
import React, { useState } from 'react';
import { Button, Container } from "react-bootstrap";
import '../App.css'

function TarotShuffle({ cards }) {
 
   
    const [selectedCards, setSelectedCards] = useState([]);
    const [question, setQuestion] = useState(''); 
    
    function handleInputChange(e) {
      setQuestion(e.target.value); // Update the question state with new input
    }

    function shuffleCards() {
      setQuestion('');
      const shuffledCards = shuffle(cards);
      setSelectedCards(shuffledCards.slice(0, 3));
    }
  
   
    function shuffle(array) {
      

      let currentIndex = array.length;
      let temporaryValue, randomIndex;
  
      while (currentIndex !== 0) {
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex -= 1;
  
        temporaryValue = array[currentIndex];
        array[currentIndex] = array[randomIndex];
        array[randomIndex] = temporaryValue;
      }
  
      return array;
    }
  
    return (
      <Container className="text-center" style={{ position: "relative", display: "flex", flexDirection: "column", alignItems: "center" }}>
      <div className="flex justify-center items-center w-full">
        <input
          type="text"
          value={question}
          onChange={handleInputChange}
          placeholder="Ask a question..."
          className="p-2 border rounded-full shadow-lg"
        />
        <Button 
          onClick={shuffleCards} 
          className="ml-4 bg-purple-900 text-white p-2 rounded-full glow shadow-lg"
        >
          Shuffle Cards
        </Button>
        <button 
          type="button" 
          className='ml-4 bg-purple-900 text-white p-2 rounded-full glow shadow-lg' 
          onClick={(e) => {
            e.preventDefault();
            window.open("https://donate.stripe.com/cN2dTv3LBaYgemQcMN", "_blank", "noopener,noreferrer");
          }}
        >
          Donate
        </button>
      </div>
      
      <div className="flex justify-center items-center w-full" style={{ minHeight: "50vh" }}>
        {selectedCards.length !== 0 ? (
          <div className="flex justify-center items-center w-full">
            {selectedCards.map((card) => (
              <TarotCard key={card.name} name={card.name} image={card.image} description={card.description}/>
            ))}
          </div>
        ) : null}
      </div>
    </Container>
    

  
    );
  }
  
  export default TarotShuffle;