import {cards} from './cards';
import TarotShuffle from '././components/TarotShuffle';
import './App.css';

function App() {
  return (
   
    <div className='App'>
       
       <div class="flex justify-center items-center">
          <h1 className='text-shadow-fuchsia-600 uppercase text-4xl sm:text-5xl md:text-6xl lg:text-7xl xl:text-8xl font-extrabold text-transparent bg-clip-text bg-custom-purple'>Tarot Delight</h1>
    </div>
    <br></br>
      <TarotShuffle cards={cards} />
        
      </div>
  );
}

export default App;

