import Fool from "./components/img/Fool_00.jpg";
import Magician from "./components/img/Magician_01.jpg";
import HighPriestess from "./components/img/HighPriestess_02.jpg";
import Empress from "./components/img/Empress_03.jpg";

import Emperor from "./components/img/Emperor_04.jpg";
import Hierophant from "./components/img/Hierophant_05.jpg";
import Lovers from "./components/img/TheLovers_06.jpg";
import Chariot from "./components/img/Chariot_07.jpg";

import Strength from "./components/img/Strength_08.jpg";
import Hermit from "./components/img/Hermit_09.jpg";
import WheelOfFortune from "./components/img/WheelOfFortune_10.jpg";
import Justice from "./components/img/Justice_11.jpg";

import HangedMan from "./components/img/HangedMan_12.jpg";
import Death from "./components/img/Death_13.jpg";
import Temperance from "./components/img/Temperance_14.jpg";

import Devil from "./components/img/Devil_15.jpg";
import Tower from "./components/img/Tower_16.jpg";
import Star from "./components/img/Star_17.jpg";

import Moon from "./components/img/Moon_18.jpg";
import Sun from "./components/img/Sun_19.jpg";

import Judgement from "./components/img/Judgement_20.jpg";
import World from "./components/img/World_21.jpg";

import AceOfCups from "./components/img/AceOfCups_01.jpg";
import TwoOfCups from "./components/img/TwoOfCups_02.jpg";
import ThreeOfCups from "./components/img/ThreeOfCups_03.jpg";
import FourOfCups from "./components/img/FourOfCups_04.jpg";

import FiveOfCups from "./components/img/FiveOfCups_05.jpg";
import SixOfCups from "./components/img/SixOfCups_06.jpg";
import SevenOfCups from "./components/img/SevenOfCups_07.jpg";
import EightOfCups from "./components/img/EightOfCups_08.jpg";

import NineOfCups from "./components/img/NineOfCups_09.jpg";
import TenOfCups from "./components/img/TenOfCups_10.jpg";
import PageOfCups from "./components/img/PageOfCups_11.jpg";
import KnightOfCups from "./components/img/KnightOfCups_12.jpg";

import QueenOfCups from "./components/img/QueenOfCups_13.jpg";
import KingOfCups from "./components/img/KingOfCups_14.jpg";

import AceOfSwords from "./components/img/AceOfSwords_01.jpg";
import TwoOfSwords from "./components/img/TwoOfSwords_02.jpg";
import ThreeOfSwords from "./components/img/ThreeOfSwords_03.jpg";
import FourOfSwords from "./components/img/FourOfSwords_04.jpg";

import FiveOfSwords from "./components/img/FiveOfSwords_05.jpg";
import SixOfSwords from "./components/img/SixOfSwords_06.jpg";
import SevenOfSwords from "./components/img/SevenOfSwords_07.jpg";
import EightOfSwords from "./components/img/EightOfSwords_08.jpg";

import NineOfSwords from "./components/img/NineOfSwords_09.jpg";
import TenOfSwords from "./components/img/TenOfSwords_10.jpg";
import PageOfSwords from "./components/img/PageOfSwords_11.jpg";
import KnightOfSwords from "./components/img/KnightOfSwords_12.jpg";

import QueenOfSwords from "./components/img/QueenOfSwords_13.jpg";
import KingOfSwords from "./components/img/KingOfSwords_14.jpg";

import AceOfWands from "./components/img/AceOfWands_01.jpg";
import TwoOfWands from "./components/img/TwoOfWands_02.jpg";
import ThreeOfWands from "./components/img/ThreeOfWands_03.jpg";
import FourOfWands from "./components/img/FourOfWands_04.jpg";

import FiveOfWands from "./components/img/FiveOfWands_05.jpg";
import SixOfWands from "./components/img/SixOfWands_06.jpg";
import SevenOfWands from "./components/img/SevenOfWands_07.jpg";
import EightOfWands from "./components/img/EightOfWands_08.jpg";

import NineOfWands from "./components/img/NineOfWands_09.jpg";
import TenOfWands from "./components/img/TenOfWands_10.jpg";
import PageOfWands from "./components/img/PageOfWands_11.jpg";
import KnightOfWands from "./components/img/KnightOfWands_12.jpg";

import QueenOfWands from "./components/img/QueenOfWands_13.jpg";
import KingOfWands from "./components/img/KingOfWands _14.jpg";

import AceOfPentacles from "./components/img/AceOfPentacles_01.jpg";
import TwoOfPentacles from "./components/img/TwoOfPentacles_02.jpg";
import ThreeOfPentacles from "./components/img/ThreeOfPentacles_03.jpg";
import FourOfPentacles from "./components/img/FourOfPentacles_04.jpg";

import FiveOfPentacles from "./components/img/FiveOfPentacles_05.jpg";
import SixOfPentacles from "./components/img/SixOfPentacles_06.jpg";
import SevenOfPentacles from "./components/img/SevenOfPentacles_07.jpg";
import EightOfPentacles from "./components/img/EightOfPentacles_08.jpg";

import NineOfPentacles from "./components/img/NineOfPentacles_09.jpg";
import TenOfPentacles from "./components/img/TenOfPentacles_10.jpg";
import PageOfPentacles from "./components/img/PageOfPentacles_11.jpg";
import KnightOfPentacles from "./components/img/KnightOfPentacles_12.jpg";

import QueenOfPentacles from "./components/img/QueenOfPentacles_13.jpg";
import KingOfPentacles from "./components/img/KingOfPentacles_14.jpg";




export const cards = [

{
    "name": "The Fool",
    "image": Fool,
    "description": "New beginnings, spontaneity, taking a leap of faith",

}, {
    "name": "The Magician",
    "image": Magician,
    "description": "Manifestation, resourcefulness, power"

}, {
    "name": "The High Priestess",
    "image": HighPriestess,
    "description": "Intuition, unconscious knowledge, mystery"

}, {
    "name": "The Empress",
    "image": Empress,
    "description": "Fertility, motherhood, abundance"

},
{
    "name": "Emperor",
    "image": Emperor,
    "description": "Authority, father-figure, structure"

}, {
    "name": "Hierophant",
    "image": Hierophant,
    "description": "Tradition, conformity, morality"

}, {
    "name": "Lovers",
    "image": Lovers,
    "description": "Love, harmony, choices"

}, {
    "name": "Chariot",
    "image": Chariot,
    "description": "Control, willpower, victory"

},
{
    "name": "Strength",
    "image": Strength,
    "description": "Courage, inner strength, compassion"

}, {
    "name": "Hermit",
    "image": Hermit,
    "description": "Solitude, search for truth, introspection"

}, {
    "name": "Wheel Of Fortune",
    "image": WheelOfFortune,
    "description": "Destiny, cycles, a pivotal point"

}, {
    "name": "Justice",
    "image": Justice,
    "description": "Fairness, truth, law"

},
{
    "name": "Hanged Man",
    "image": HangedMan,
    "description": "Sacrifice, letting go, surrendering"

}, {
    "name": "Death",
    "image": Death,
    "description": "Endings, transformation, transition"

}, {
    "name": "Temperance",
    "image": Temperance,
    "description": "Balance, moderation, patience"

}, {
    "name": "Devil",
    "image": Devil,
    "description": "Bondage, materialism, temptation"

},
{
    "name": "Tower",
    "image": Tower,
    "description": "Sudden change, upheaval, chaos"

}, {
    "name": "Star",
    "image": Star,
    "description": "Hope, inspiration, serenity"

}, 
{
    "name": "Moon",
    "image": Moon,
    "description": "Illusion, confusion, intuition"

}, {
    "name": "Sun",
    "image": Sun,
    "description": "Joy, success, celebration"

}, 
{
    "name": "Judgement",
    "image": Judgement,
    "description": "Judgment, rebirth, inner calling"

}, {
    "name": "World",
    "image": World,
    "description": "Completion, integration, accomplishment"

}, 

{
    "name": "Ace Of Cups",
    "image": AceOfCups,
    "description": "New emotional beginnings, love, joy"

}, {
    "name": "Two Of Cups",
    "image": TwoOfCups,
    "description": "Emotional balance, partnership, mutual respect"

}, {
    "name": "Three Of Cups",
    "image": ThreeOfCups,
    "description": "Celebration, friendship, collaboration"

}, {
    "name": "Four Of Cups",
    "image": FourOfCups,
    "description": "Apathy, contemplation, disconnectedness"

},
{
    "name": "Five Of Cups",
    "image": FiveOfCups,
    "description": "Loss, grief, disappointment"

}, {
    "name": "Six Of Cups",
    "image": SixOfCups,
    "description": "Nostalgia, happy memories, reunion"

}, {
    "name": "Seven Of Cups",
    "image": SevenOfCups,
    "description": "Choices, daydreams, illusions"

}, {
    "name": "Eight Of Cups",
    "image": EightOfCups,
    "description": "Abandonment, withdrawal, contemplation"

},
{
    "name": "Nine Of Cups",
    "image": NineOfCups,
    "description": "Emotional fulfillment, contentment, happiness"

}, {
    "name": "Ten Of Cups",
    "image": TenOfCups,
    "description": "Emotional happiness, harmony, family"

}, {
    "name": "Page Of Cups",
    "image": PageOfCups,
    "description": "Creative opportunities, intuition, curiosity"
},
{
    "name": "Knight Of Cups",
    "image": KnightOfCups,
    "description": "Romance, charm, Knight in shining armor"

}, {
    "name": "Queen Of Cups",
    "image": QueenOfCups,
    "description": " Emotional balance, compassion, intuition"

}, {
    "name": "King Of Cups",
    "image": KingOfCups,
    "description": "Emotional wisdom, calm, diplomacy"
},

{
    "name": "Ace Of Swords",
    "image": AceOfSwords,
    "description": "Clarity, breakthrough, sharp mind"

}, {
    "name": "Two Of Swords",
    "image": TwoOfSwords,
    "description": "Indecision, choices, truce"

}, {
    "name": "Three Of Swords",
    "image": ThreeOfSwords,
    "description": "Heartbreak, emotional pain, sorrow"

}, {
    "name": "Four Of Swords",
    "image": FourOfSwords,
    "description": "Rest, relaxation, contemplation"

},
{
    "name": "Five Of Swords",
    "image": FiveOfSwords,
    "description": "Conflict, tension, loss"

}, {
    "name": "Six Of Swords",
    "image": SixOfSwords,
    "description": "Transition, change, rite of passage"

}, {
    "name": "Seven Of Swords",
    "image": SevenOfSwords,
    "description": "Deception, trickery, tactics and strategy"

}, {
    "name": "Eight Of Swords",
    "image": EightOfSwords,
    "description": "Restriction, confusion, powerlessness"

},
{
    "name": "Nine Of Swords",
    "image": NineOfSwords,
    "description": "Anxiety, worry, fear"

}, {
    "name": "Ten Of Swords",
    "image": TenOfSwords,
    "description": "Betrayal, backstabbing, defeat"

}, {
    "name": "Page Of Swords",
    "image": PageOfSwords,
    "description": "Curiosity, restlessness, mental energy"
},
{
    "name": "Knight Of Swords",
    "image": KnightOfSwords,
    "description": "Ambition, action, quick thinking"

}, {
    "name": "Queen Of Swords",
    "image": QueenOfSwords,
    "description": "Independent, unbiased judgement, clear boundaries"

}, {
    "name": "King Of Swords",
    "image": KingOfSwords,
    "description": "Intellectual power, authority, truth"
},

{
    "name": "Ace Of Wands",
    "image": AceOfWands,
    "description": "Inspiration, new opportunities, growth"

}, {
    "name": "Two Of Wands",
    "image": TwoOfWands,
    "description": "Future planning, progress, decisions"

}, {
    "name": "Three Of Wands",
    "image": ThreeOfWands,
    "description": "Preparation, foresight, enterprise"

}, {
    "name": "Four Of Wands",
    "image": FourOfWands,
    "description": "Celebration, harmony, marriage"

},
{
    "name": "Five Of Wands",
    "image": FiveOfWands,
    "description": "Conflict, competition, tension"

}, {
    "name": "Six Of Wands",
    "image": SixOfWands,
    "description": "Victory, success, public recognition"

}, {
    "name": "Seven Of Wands",
    "image": SevenOfWands,
    "description": "Defense, courage, persistence"

}, {
    "name": "Eight Of Wands",
    "image": EightOfWands,
    "description": "Speed, action, air travel"

},
{
    "name": "Nine Of Wands",
    "image": NineOfWands,
    "description": "Resilience, courage, persistence"

}, {
    "name": "Ten Of Wands",
    "image": TenOfWands,
    "description": "Burden, responsibility, hard work"

}, {
    "name": "Page Of Wands",
    "image": PageOfWands,
    "description": "Enthusiasm, exploration, discovery"
},
{
    "name": "Knight Of Wands",
    "image": KnightOfWands,
    "description": "Energy, passion, lust, action"

}, {
    "name": "Queen Of Wands",
    "image": QueenOfWands,
    "description": "Courage, confidence, independence"

}, {
    "name": "King Of Wands",
    "image": KingOfWands,
    "description": "Natural-born leader, vision, entrepreneur"
},

{
    "name": "Ace Of Pentacles",
    "image": AceOfPentacles,
    "description": "New financial or career opportunity, manifestation, abundance"

}, {
    "name": "Two Of Pentacles",
    "image": TwoOfPentacles,
    "description": "Balance, adaptability, time management"

}, {
    "name": "Three Of Pentacles",
    "image": ThreeOfPentacles,
    "description": "Teamwork, initial fulfillment, collaboration"

}, {
    "name": "Four Of Pentacles",
    "image": FourOfPentacles,
    "description": "Financial security, saving, conservatism"

},
{
    "name": "Five Of Pentacles",
    "image": FiveOfPentacles,
    "description": "Financial loss, poverty, insecurity"

}, {
    "name": "Six Of Pentacles",
    "image": SixOfPentacles,
    "description": "Generosity, charity, giving and receiving"

}, {
    "name": "Seven Of Pentacles",
    "image": SevenOfPentacles,
    "description": "Long-term vision, sustainable results, investment"

}, {
    "name": "Eight Of Pentacles",
    "image": EightOfPentacles,
    "description": "Apprenticeship, skill development, dedication"

},
{
    "name": "Nine Of Pentacles",
    "image": NineOfPentacles,
    "description": "Abundance, luxury, self-sufficiency"

}, {
    "name": "Ten Of Pentacles",
    "image": TenOfPentacles,
    "description": "Wealth, inheritance, family"

}, {
    "name": "Page Of Pentacles",
    "image": PageOfPentacles,
    "description": "Financial opportunity, learning, dedication"
},
{
    "name": "Knight Of Pentacles",
    "image": KnightOfPentacles,
    "description": "Efficiency, routine, conservatism"

}, {
    "name": "Queen Of Pentacles",
    "image": QueenOfPentacles,
    "description": "Practicality, creature comforts, financial security"

}, {
    "name": "King Of Pentacles",
    "image": KingOfPentacles,
    "description": "Wealth, business, leadership, security"
},










]