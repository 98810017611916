import React, { useState } from 'react';
import { Card } from 'react-bootstrap';
import '../App.css'
function TarotCard({ name, image, description }) {
    const [isFlipped, setIsFlipped] = useState(false);

    const handleFlip = () => {
      setIsFlipped(!isFlipped);
    };

    console.log("Description:", description);

  return (
    <div className="card-container" onClick={handleFlip}>
    <div className={isFlipped ? "card flipped" : "card"}>
      <div className="front">
        <Card>
          <Card.Img variant="top" src={image} style={{ width: "200px", height: "300px" }}/>
          <Card.Body>
            <Card.Title className='text-white'>{name}</Card.Title>
          </Card.Body>
        </Card> 
      </div>
      <div className="back">
        <Card>
          <Card.Body>
            <Card.Title className='text-white'>{description || "No description available."}</Card.Title>
          </Card.Body>
        </Card>
      </div>
    </div>
  </div>
  

  );
}


  export default TarotCard 